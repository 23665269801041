// 使用方式 this.$store.dispatch('setItem',{key:value})
// 使用方式 this.$store.commit('setItem',{key:value})
const modules = {
  common:{
    state(){
      return {
        isConnect:true,
        userInfo:null,
        signInfo:null,
        adminInfo:null
      }
    },
    mutations:{
      // commit
      setItem(state,data){
        // console.log(data)
        for(let key in data){
          state[key] = data[key];
        }
      }
    },
    // dispatch
    actions:{
      setItem(context,data){
        context.commit("setItem",data)
      }
    }
  }
}

export default modules;