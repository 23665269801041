// 工具模块
import config from "@/common/config"
const tool = {
  setCookie(key,value,time=24){
    key =  tool.mergeKey(key)
    var date = new Date();
    date.setTime(date.getTime()+(time*60*60*1000));
    var expires = "expires="+date.toGMTString();
    document.cookie = key + "=" + value + "; " + expires;
  },
  getCookie(key){
    key = tool.mergeKey(key)
    var name = key + "=";
    var params = document.cookie.split(';');
    for(var i=0; i<params.length; i++) 
    {
      var item = params[i].trim();
      if (item.indexOf(name)==0) return item.substring(name.length,item.length);
    }
    return "";
  },
  setLocalCookie(key,value,time=24){
    key = tool.mergeKey(key)
    let data = {
      val:value,
      time:Date.now() + time * 60 * 60 * 1000
    }
    tool.setStorage(`${key}_cookie`,data)
    
  },
  getLocalCookie(key){
    key = tool.mergeKey(key)
    let data = tool.getStorage(`${key}_cookie`) || {val:'',time:0}
    if(Date.now() < data.time){
      return data.val;
    }else{
      return '';
    }
  },
  setStorage(key,val,storage){
    key = tool.mergeKey(key)
    storage = storage || localStorage
    let data = {
      value:val
    }
    storage.setItem(key,JSON.stringify(data));
  },
  getStorage(key,storage){
    key = tool.mergeKey(key)
    storage = storage || localStorage
    let data = storage.getItem(key);
    if(!data) return data;
    data = JSON.parse(data);
    return data.value
  },
  mergeKey(key){
    return  `${config.prefix}${key}`
  },
  getURL(url,data={}){
    let index = 0;
    for(let key in data){
      let _symbol = index++ === 0 ? '?' : '&';
      url+=`${_symbol}${key}=${data[key]}`
    }
    return url;
  },
  
  fotmatData(key,value){
    if(value==='' || value === null) return '暂无';
    let text = '';
    switch(key){
      default:
        text = value;
    }
    return text;
  },
  getChatList(meetingInfo){
    let chatList = []
    let ids = []
    meetingInfo.chat_info.forEach(chat=>{
      if(!chat.id) chatList.push(chat)
      else if(!ids.includes(chat.id)){
        chatList.push(chat)
        ids.push(chat.id)
      }
    })
    return chatList
  },
  // 获取当前日期
  getDate(time,format="yyyy-MM-dd"){
    let date = tool.getFormatTime(time),
    dateItem = {
      yyyy:date.getFullYear(),
      MM:tool.getTimeText(date.getMonth() + 1),
      dd: tool.getTimeText(date.getDate()),
      hh: tool.getTimeText(date.getHours()),
      mm: tool.getTimeText(date.getMinutes()),
      ss: tool.getTimeText(date.getSeconds())
    };
    let text = format;
    for(let key in dateItem){
      text = text.replace(key,dateItem[key])
    }
    return text;
  },
  
  getTimeText(time){
    time = String(time)
    return time[1] ? time : `0${time}`
  },
  getTipInfo(content,value){
    if(typeof value  != 'object'){
      content =  content.replace(/\$\$/g,value)
    }else{
      for(let val of value){
        content = content.replace("$$",val)
      }
    }
    return content
  },
  getDateConfig() {
    let start = 6;
    let space = 15;
    let list = []
    for(let i=start;i<=22;i++){
        for(let j=0;j<60;j+=space){
            if(i>=22&&j>0){
              break;
            }
            list.push(`${tool.getTimeText(i)}:${tool.getTimeText(j)}`)
        }
    }
    return list;
  },
  // 获取上周日期
  getPrepWeek(){
    let date = new Date();
    let day = date.getDay();
    let curTime = date.getTime();
    let prepstartDay = curTime - (day + 7 - 1 ) * 24 * 60 * 60 * 1000;
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    return {
      start_time:tool.getDate(prepstartDay),
      end_time:tool.getDate(prepEndDay)
    }
  },
  // 获取上个月日期
  getPrepMonth(){
    let date = new Date();
    let day = date.getDate();
    let curTime = date.getTime();
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    let preDate = new Date(prepEndDay);
    let preTime = preDate.getTime();
    let preYear = preDate.getFullYear();
    let preMonth = preDate.getMonth() + 1;
    let preCountDay = new Date(preYear,preMonth,0).getDate()
    let prepstartDay = preTime - (preCountDay - 1) * 24 * 60 * 60 * 1000;
    return {
      start_time:tool.getDate(prepstartDay),
      end_time:tool.getDate(prepEndDay)
    }
  },
  // 获取时间戳
  getFormatTime(time=""){
    if(typeof time == "object" && time != null) return time;
    if(typeof time == "string") time = time.replace(/-/g,"/");
    return time ? new Date(time) : new Date();
  },
  getTime(time){
    let value = 0;
    try{
      let dealWithTime = tool.getFormatTime(time)
      value = dealWithTime.getTime();
    }catch(err){ console.log(err)}
    return value
  },
  getTimePhase(time){
    time = time/1000;
    if(time < 60) return 0;
    if(time < 60 * 60) return 1;
    if(time < 60 * 60 * 24 ) return 2;
    if(time > 60 * 60 * 24) return 3;
  },
  getShowTime(time,format="",showLen=0){
    let defaultFormat = format || ["秒","分钟","小时","天"];
    let text = "";
    time = time || 0;
    let phase = showLen ?? tool.getTimePhase(time)
    time = Math.floor(time/1000)
    let dataList = [
      time % 60,
      Math.floor( time / 60 ) % 60,
      Math.floor( time / ( 60 * 60 ) ) % 60,
      Math.floor( time / ( 24 * 60 * 60 ) ) % 24
    ]
    for(let len= defaultFormat.length,index = 0; index < len; index++ ){
      if(dataList[index] <= 0 && index >= phase) break;
      let content = index == 0 ? dataList[index] : tool.getTimeText(dataList[index])
      text = `${content}${defaultFormat[index]}${text}`
    }
    return text;
  },
  getSystemInfo(){
    let app = navigator.appVersion;
    let leftIndex=app.indexOf('(');
    let rightIndex=app.indexOf(')');
    let text=app.substring(leftIndex+1,rightIndex); 
    text = text.split(";");
    var Mobile_Iphone=text[0];
    var Mobile_Android=text[2];
    var res=/Android/;
    var result=res.test(Mobile_Android);
    let data = {
      device:Mobile_Iphone,
      version:'',
      width:window.screen.width,
      height:window.screen.height,
    }
    switch(Mobile_Iphone){
      case 'Linux':
        let device = ''
        if(result){
          device = text[4]
          data.version = text[2]
        }else{
          device = Mobile_Android
          data.version = text[1]
        }
        data.device = device.split('Build')[0]
        break;
      case 'iPhone':;
      case 'iPad':
        data.device = text[0];
        let version = text[1].match(/OS\s([\d\_]+)\slike/g);
        version = version ? version[0] : '';
        data.version = version.split(/\s/)[1] ?  `ios ${version.split(/\s/)[1]}`: ''
        break;
    }
    data.device = String(data.device).trim()
    data.version = String(data.version).trim().replace(/_/g,'.')
    return data;
    
  },
  
  isGuest(meetingInfo){
    return true
    // return Number(meetingInfo.role) != 1
  },
  
  getOpenid(){
    return tool.getCookie('openid') || ''
  },
  getPhone(){
    return tool.getCookie('ophone') || ''
  },
  getConfig(){
    if(tool.isDevelopment() || tool.isLocal()){
      return {
        appid:"wxf7bf3dd76862e21a"
      }
    }else{
      return {
        appid:"wxf7bf3dd76862e21a"
      }
    }
  },
  getQuery(){
    let searchText = location.search.split(/[\?\&]/g);
    let search = {}
    for(let text of searchText){
      let [key,value]= text.split("=")
      search[key] = value ||""
    }
    return search
  },
  jumpToGetOpenid(to){
    let query = to.query
    query.path = window.location.hash.split("/")[1] ||""
    let jumpURL = tool.getURL(window.location.origin,query)
    const appid = tool.getConfig().appid
    const url =`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(jumpURL)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
    window.location.replace(url);
  },
  getShowTime:(time,format="yyyy年MM月dd天hh小时mm分钟ss秒") => {
    let text = format;
    time = time || 0;
    time = Math.floor(time/1000)
    let dataList = {
      ss:time % 60,
      mm:Math.floor( time / 60 ) % 60,
      hh:Math.floor( time / ( 60 * 60 ) ) % 60,
      dd:Math.floor( time / ( 24 * 60 * 60 ) ) % 24,
      MM:0,
      yyyy:0
    }
    for(let key in dataList){
      if(!dataList[key] && !["mm","ss"].includes(key)){
        let reg = new RegExp(`${key}[\u4300-\u9fa5-\s:]{0,}`,"g")
        text = text.replace(reg,"")
      }else{
        let val = key == "yyyy" ? dataList[key] : tool.getTimeText(dataList[key])
        text = text.replace(key,val)
      }
    }
    return text;
  },
  
  isAndroid(){
    return window.navigator.userAgent.indexOf('iPhone') == -1;
  },
  sortChinese(data,key){
    let dataList = []
    dataList = key ? data.sort((params1,params2)=>{
      return params1[key].localeCompare(params2[key],"zh")
    })
    :
    dataList = data.sort((params1,params2)=>{
      return params1.localeCompare(params2,"zh")
    })
    return dataList;
  },
  // type 0 为文本 1 为聊天
  getChatContent(content="",name="",type=0,voice_length=0){
    return {
      content:content,
      name,
      openid:tool.getOpenid(),
      content_type:type,
      voice_length
    }
  },
  calcChatCount(meetingInfo,phase=3){
    let chatList = tool.getChatList(meetingInfo)
    chatList =chatList.filter(chat=>chat.content_type == 1)
    try{
    switch(phase){
      case 1:
        if(meetingInfo.control.length >= 2){
          let endTime = tool.getTime(meetingInfo.control[1].create_time)
          chatList = chatList.filter(chat=>{
            return tool.getTime(chat.create_time) < endTime
          })
        }
        break;
      case 3:
        let startTime = tool.getTime(meetingInfo.control[2].create_time)
        chatList = chatList.filter(chat=>{
          return tool.getTime(chat.create_time) >= startTime
        })
        break;
    }
    return chatList.reduce((count,chat)=>(Math.ceil(chat.voice_length) || 0 ) + count,0)
    }catch(err){
      return err
    }
  },
  isLocal(){
    const localURL = /^(192\.168\.\d{1,3}\.\d{1,3})|(localhost)|(127.0.0.1)|(172\.16\.\d{1,3}\.\d{1,3})$/;
    let hostname = window.location.hostname;
    return localURL.test(hostname)
  },
  isDevelopment(){
    const localURL = /bzjt\.dev\./;
    let hostname = window.location.hostname;
    return localURL.test(hostname)
  },
  dealWithText(content,count=0){
    const enterSeparator = "xxxxxx";
    content = content.replace(/\n/g,enterSeparator)
    let contentList = content.split(/[\s]/g)
    let resultList = contentList.map(val=>{
      let list = val.match(/([^\w]+)|(\w+)/g) || [];
      list = list.map(x=>{
        return /\w+/g.test(x) ? x : x.split("")
      })
      return list.flat()
    })
    resultList = resultList.flat();
    let result = "";
    let fieldReg = /[\u4e00-\u9fa5a-zA-Z0-9]+/
    let wordsCount = 0;
    // count = count || resultList.length;
    // count = count < resultList.length ? count : resultList.length
    for(let index in resultList){
      let val = resultList[index]
      let separator = /\w+/g.test(val) && !/^\d+$/g.test(val) && index !== 0 ? " ": ""
      result += `${separator}${val}`
      if(fieldReg.test(val)) wordsCount += 1;
      if(count != 0 && wordsCount >= count) break;
    }
    let enterReg = new RegExp(enterSeparator,"g")
    return {
      content:result.replace(enterReg,"\n"),
      count:wordsCount
    }
  },
  getCityConfig:async function(){
    return new Promise((resolve)=>{
      import('@/common/citys.json').then(data=>{
        resolve(data.default)
      })
    })
  },
  compressImage:(file, convertToBase64 = false ) => { // max=默认最大压缩到2M
      return new Promise((resolve)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file); // 读出 base64
        reader.onloadend = ev => {
            let dataURL = ev.target.result;
            // 下面逻辑处理
            let file_mb = dataURL.length / (1024.0 * 1024);
            console.log("file_mb====", file_mb);
            let img = new Image();
            img.src = dataURL;
            if (file_mb <= 2) {
                return convertToBase64 ? resolve(ev.target.result) : resolve(file)
            }
            img.onload = () => {
                const canvas = document.createElement('canvas');
                if (!canvas) {
                    return convertToBase64 ? resolve(ev.target.result) : resolve(file)
                }
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    return convertToBase64 ? resolve(ev.target.result) : resolve(file)
                }
                const width = 750;
                const height = img.height*(750/img.width);
                canvas.width = width;
                canvas.height = height;
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, width, height);
                ctx.drawImage(img, 0, 0, width, height);
                const size = 0.4 //转换率，经测试0.4最好
                if (convertToBase64) {
                    const base64 = canvas.toDataURL('image/jpeg', size)
                    resolve(base64)
                    return
                }
                canvas.toBlob(function (blob) {
                    if(file?.name && blob){
                      blob.name = file.name
                    }
                    resolve(blob)
                }, 'image/jpeg', size)
            }
        }
    })
  }
}

export default tool;