//  微信
import config from '@/common/config'
import axios from '@/common/axios'
import urls from '@/common/urls'
// import tool from '@/common/tool'
import store from '@/store'
import wx from 'weixin-js-sdk'
const initWx = () =>{
    if(store.state.common.app_id) return;
    const url = `${urls.jssign}?url=${encodeURIComponent(window.location.href.split('#')[0])}`;
    axios.get(url).then(res=>{
        const data = res.data;
        store.dispatch('setItem',{app_id:data.app_id})
        wx.config({
            debug:false,
            appId:data.app_id,
            timestamp:data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature,
            jsApiList:config.wxinterface
        })
    }).catch(err=>{
        console.log("微信SDK校验失败")
    })
}
wx.ready(()=>{
    // initShare();
    // initMenu();
})
const shareMessage = (info) => {
    let url = info.url || window.location.href;
    let logo = config.logo
    wx.updateAppMessageShareData({ 
        title: info.title, // 分享标题
        desc: info.description, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })
    wx.updateTimelineShareData({ 
        title:  info.title, // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })

}
const initMenu = () => {
    wx.hideAllNonBaseMenuItem();
    wx.showMenuItems({
        menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:favorite',
        ] // 要显示的菜单项，所有menu项见附录3
    });
}
const selectFile = async (count,meetId) => {
    return new Promise((resolve,reject)=>{
        wx.chooseImage({
            count: count, // 默认9
            sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: async function (res) {
                var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
                // console.log("localIds=====",localIds)
                try{
                    let serverIds = await uploadMoreFiles(localIds)
                    console.log("serverid列表",serverIds)
                    let data = {
                        meet_id:meetId,
                        ids:serverIds
                    }
                    let res = await axios.post(urls.uploads.uploadWeixin,data)
                    console.log("获取结果",res)
                    resolve(res.urls)
                }catch(err){
                    console.log("整体报错",err)
                    reject()
                }
            },
            cancel:function(){
                reject()
            },
            error: function(err){
                console.log("选择图片错误",err)
                reject()
            }
        });
    })
}
const uploadSingleFile = async  (localId) => {
    return new Promise((resolve,reject)=>{
        wx.uploadImage({
            localId: localId, // 需要上传的图片的本地ID，由 chooseImage 接口获得
            isShowProgressTips: 0, // 默认为1，显示进度提示
            success: function (res) {
                console.log("上传单张图片成功",res)
                resolve(res.serverId)
            },
            error: function(err){
                console.log('微信上传图片错误，',err)
                reject()
            }
        });
    })

}
const uploadMoreFiles = async (localIds) => {
    let data = []
    for(let index in localIds){
      let result = await uploadSingleFile(localIds[index])
      data[index] = result;
    }
    return data;
}
export default {
    init:initWx,
    shareMessage,
    initMenu,
    selectFile,
    wx
}